import { get, repeat } from 'lodash/fp'
import { formatPrice } from 'shared/components/ProductGrid/__utils__/formatPrice'
import useCurrency from 'shared/hooks/useCurrency'
import { fonts, media } from 'shared/lib'
import IProduct from 'shared/types/IProduct'
import styled from 'styled-components'
import GridItem from './GridItem'
import { t } from 'localization'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'

const TP = 'shared.components.ProductGrid.ProductGrid'

interface IProductGridProps {
  dataTrackingEvent?: (...args: any) => void
  ensureNoWidow?: boolean
  hideAdditionalPrices?: boolean
  hidePrices?: boolean
  maxColumns?: number
  products: IProduct[]
}

export const ProductGrid = ({
  dataTrackingEvent,
  ensureNoWidow = false,
  hideAdditionalPrices = false,
  hidePrices = false,
  maxColumns = 4,
  products,
}: IProductGridProps): JSX.Element => {
  const DEFAULT_SELECTED_CURRENCY_ISO = 'USD'
  const DEFAULT_SELECTED_CURRENCY_SYMBOL = '$'
  const { availableCurrencies, currencyCode } = useShoppingRegionContext()

  const { selectedCurrency } = useCurrency()
  const isoCode = selectedCurrency?.isoCode || DEFAULT_SELECTED_CURRENCY_ISO
  const displaySymbol =
    availableCurrencies.find((selectedCurrency) => selectedCurrency.isoCode === currencyCode)
      ?.symbol || DEFAULT_SELECTED_CURRENCY_SYMBOL

  return !!products.length ? (
    <Grid maxColumns={maxColumns} ensureNoWidow={ensureNoWidow}>
      {products.map(({ id, name, slug, pictureUrl, price, brandName }, i) => {
        const localizedPrice = get('localizedValue')(price)
        const additionalPriceSymbol = hideAdditionalPrices ? '' : '+'

        return (
          <GridItem
            brand={brandName}
            currency={isoCode}
            dataTrackingEvent={dataTrackingEvent}
            hidePrice={hidePrices}
            imageUrl={pictureUrl}
            index={i}
            key={id}
            name={name}
            price={formatPrice(localizedPrice, isoCode, displaySymbol, additionalPriceSymbol)}
            productUrl={slug}
          />
        )
      })}
    </Grid>
  ) : (
    <NoResults data-testid="productGridNoResults">
      <Title>{t(`${TP}.noResTitle`, 'No Results Found')}</Title>
      <SubTitle>
        {t(`${TP}.noResultDesc`, 'Please check your filters or search for another sneaker')}
      </SubTitle>
    </NoResults>
  )
}

export default ProductGrid

const Grid = styled.div<{ maxColumns: number; ensureNoWidow: boolean }>`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: ${repeat(1)(' 1fr')};
  ${media.extraSmall`
    grid-template-columns:${repeat(2)(' 1fr')};
  `}
  ${media.medium`
    grid-gap: 20px;
    grid-template-columns:${repeat(3)(' 1fr')};
    ${({ ensureNoWidow }: { ensureNoWidow: boolean }) =>
      ensureNoWidow &&
      `a:last-child:nth-child(3n-2) {
        display: none;
      }
    `}
  `}
  ${media.large`
    ${({ maxColumns }: { maxColumns: number }) =>
      `grid-template-columns:${repeat(maxColumns)(' 1fr')};`}
    ${({ ensureNoWidow }: { ensureNoWidow: boolean }) =>
      ensureNoWidow &&
      `a:last-child:nth-child(3n-2) {
        display: initial;
      }
    `}
  `}
`

const NoResults = styled.div`
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.large`
    height: 600px;
  `}
`

const Title = styled.span`
  ${fonts.SUBTITLE_2}
  margin-top: 40px;
  margin-bottom: 10px;
`

const SubTitle = styled.span`
  ${fonts.BODY_TEXT}
`
