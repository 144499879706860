import { get, toLower } from 'lodash/fp'
import { Currency } from 'shared/enums'
import { SearchHit } from './mapHitsToProducts'

const createGetLocaleLowestPriceCents = (currency) => get(`lowest_price_cents_${toLower(currency)}`)

const getUsdAmount = createGetLocaleLowestPriceCents(Currency.USD)

const getPriceFromHit = (currency: string) => (hit: SearchHit) => {
  const getLocalizedAmount = createGetLocaleLowestPriceCents(currency)
  const localizedAmount = getLocalizedAmount(hit)
  const usdAmount = getUsdAmount(hit)

  return {
    localizedValue: localizedAmount,
    value: usdAmount,
  }
}

export default getPriceFromHit
