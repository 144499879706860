import { Hit } from 'react-instantsearch-core'
import getPriceFromHit from './getPriceFromHit'

export interface SearchHit {
  objectID: string
  name: string
  url: string
  slug: string
  grid_picture_url: string
  lowest_price_cents: number
  brand_name: string
}
export interface ConsumerSearchHit {
  id: string
  name: string
  url: string
  slug: string
  pictureUrl: string
  price?: {
    localizedValue: string | null
  }
  brandName: string
}
export const consumerSearchMapHitsToProducts = (hits: Hit<ConsumerSearchHit>[]) => {
  return hits.map((hit) => {
    const { id, name, slug, pictureUrl, brandName, price } = hit
    return {
      id,
      name,
      slug,
      pictureUrl,
      price: {
        localizedValue: price?.localizedValue * 100,
      },
      brandName,
    }
  })
}
export const mapHitsToProducts = (currency: string) => (hits: Hit<SearchHit>[]) => {
  return hits.map((hit) => {
    const { objectID: id, name, slug, grid_picture_url: pictureUrl, brand_name: brandName } = hit
    return {
      id,
      name,
      slug,
      pictureUrl,
      price: getPriceFromHit(currency)(hit),
      brandName,
    }
  })
}

export default mapHitsToProducts
