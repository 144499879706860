import { ComponentClass, FC } from 'react'
import { Hit } from 'react-instantsearch-core'
import { connectHits } from 'react-instantsearch-dom'
import mapHitsToProducts, { SearchHit } from 'search/utils/mapHitsToProducts'
import { GridContent } from 'shared/components/ProductGrid/GridContent'
import ProductGrid from 'shared/components/ProductGrid/ProductGrid'
import useCurrency from 'shared/hooks/useCurrency'

interface IProductHitsProps {
  hidePrices?: boolean
  hits: Hit<SearchHit>[]
  isAlgolia?: boolean
}

export const Hits: FC<IProductHitsProps> = ({ isAlgolia, hits, hidePrices }) => {
  const { selectedCurrency, loading, error } = useCurrency()
  if (loading || error) {
    return null
  }

  const currencyIsoCode = selectedCurrency?.isoCode || ''
  const products = isAlgolia === false ? hits : mapHitsToProducts(currencyIsoCode)(hits)

  return (
    <GridContent>
      <ProductGrid products={products} hidePrices={hidePrices} />
    </GridContent>
  )
}

export const ProductHits: ComponentClass<any, IProductHitsProps> = connectHits(Hits)
export default ProductHits
